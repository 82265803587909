import { qspost } from '@/config/axios';

//获取数据
export const getList = data => qspost("request?", data, "videosTypeList");

//新增数据
export const add = data => qspost("request?", data, "addVideosType");

//修改状态
export const freezeThaw = data => qspost("request?", data, "lockVideosType");

//编辑数据
export const edit = data => qspost("request?", data, "updateVideosType");

//删除数据
export const del = data => qspost("request?", data, "deleteVideosType");