<template>
  <div id="bg">
    <div class="search">
      <div class="search-box">
        名字: <el-input placeholder="请输入分类名字" v-model="search.title"></el-input>
        状态:
        <el-select v-model="search.status">
          <el-option label="全部" value="-1">全部</el-option>
          <el-option value="0" label="未启用">未启用</el-option>
          <el-option value="1" label="启用">启用</el-option>
        </el-select>
        <el-button type="primary" size="medium" @click="getList">查询</el-button>
        <el-button type="primary" size="medium" @click="dialogAdd = true,title = '添加分类'">添加分类</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="id" label="ID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="weight" label="权重" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="title" label="分类名称" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="title_disable" label="专题显示" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <el-switch
              v-model="scope.row.title_disable"
              active-color="#13ce66"
              :active-value=0
              :inactive-value=1
              disabled
          >
          </el-switch>
        </template>
      </el-table-column>
      <el-table-column prop="status" label="状态" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <div :style="{color: scope.row.status == '启用' ? '#33C933' : '#F33333' }">{{scope.row.status}}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250px" align="center">
        <template #default="scope">
          <span class="operation" @click="freezeThaw(scope.row,scope.row.status == '启用' ? '禁用' : '启用')">{{scope.row.status == '启用' ? '禁用' : '启用'}}</span>
          <span class="operation" @click="editor(scope.row)">编辑</span>
          <!-- <span class="operation" @click="del(scope.row)">删除</span> -->
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" @query="getList()" />

    <!-- 添加修改 -->
    <el-dialog :title="title" v-model="dialogAdd" width="492px" top="35vh" :close-on-click-modal="false" @close="closeDialog">
      <div class="form_box">
        <div class="form_title">分类名称：</div>
        <el-input v-model="dialogArr.title" placeholder="分类名称" size="small"></el-input>
      </div>
      <div class="form_box">
        <div class="form_title">专题显示：</div>
        <el-switch
            v-model="dialogArr.title_disable"
            active-color="#13ce66"
            :active-value=0
            :inactive-value=1
        >
        </el-switch>
      </div>
      <div class="form_box">
        <div class="form_title">权重：</div>
        <el-input v-model="dialogArr.weight" placeholder="权重" size="small" type="number"></el-input>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="add" v-if="title == '添加分类'">确认添加</el-button>
          <el-button type="primary" @click="edit" v-else>确认修改</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { getList, add, freezeThaw, edit, del } from "@/api/video/classify";
import page from "@/components/page";
export default {
  name: "videoClass",
  components: {
    page,
  },
  data() {
    return {
      pager: { total: 0, page: 1, rows: 10 },
      tableData: [{}], //数据
      row: [], //选中的数据
      title: "", //弹窗标题
      dialogAdd: false, //添加弹窗
      search:{
        status:"-1",
        title:""
      },
      dialogArr: { title: "", weight: 0,title_disable:0 }, //添加数组
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.getList();
  },
  methods: {
    //获取数据
    getList() {
      getList([
        { key: "page", val: this.pager.page },
        { key: "row", val: this.pager.rows },
        { key: "status", val: this.search.status },
        { key: "title", val: this.search.title },
      ])
        .then((res) => {
          if (res.code == 0) {
            this.pager.total = res.data.total;
            this.tableData = res.data.list;
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //添加数据
    add() {
      if(this.dialogArr.weight < 0) return this.$message({ message: "权重不能未负数", type: "error" });
      add([{ key:"title", val: this.dialogArr.title },{ key: "weight", val: this.dialogArr.weight },{ key: "title_disable", val: this.dialogArr.title_disable }])
        .then((res) => {
          if (res.code == 0) {
            this.dialogAdd = false;
            this.getList();
            this.$message({ message: res.data, type: "success" });
          } else {
            this.$message({ message: res.msg, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //把数据放入编辑内
    editor(row) {
      this.row = row;
      this.title = `编辑分类(ID：${row.id})`;
      this.dialogArr = { title: row.title, weight: row.weight,title_disable:row.title_disable };
      this.dialogAdd = true;
    },
    //编辑数据
    edit() {
      if(this.dialogArr.weight < 0) return this.$message({ message: "权重不能未负数", type: "error" });
      edit([
        { key:"id", val: this.row.id },
        { key:"title", val: this.dialogArr.title },
        { key: "weight", val: this.dialogArr.weight },
        { key: "title_disable", val: this.dialogArr.title_disable }
      ])
        .then((res) => {
          if (res.code == 0) {
            this.dialogAdd = false;
            this.getList();
            this.$message({ message: res.data, type: "success" });
          } else {
            this.$message({ message: res.data, type: "error" });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //状态修改
    freezeThaw(row,str) {
      this.row = row;
      this.$confirm(
        "你确定要" + str + "视频分类：" + row.title + "吗？",
        "提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      )
        .then(() => {
          freezeThaw([
            { key:"id", val: this.row.id }
          ])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: res.data, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //删除数据
    del(row) {
      this.row = row;
      this.$confirm(
        "你确定要删除视频分类：" + row.title + "吗？此操作无法撤回,请谨慎操作",
        "提示",
        { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }
      )
        .then(() => {
          del([{ key:"id", val: this.row.id }])
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.$message({ message: res.data, type: "success" });
              } else {
                this.$message({ message: res.msg, type: "error" });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //关闭弹窗清空数据
    closeDialog() {
      this.dialogArr = { title: "", weight: 0 };
      this.row = [];
    },
  },
};
</script>

<style scoped>
.form_box {
  overflow: hidden;
  margin-bottom: 18px;
}
.form_title {
  width: 30%;
  float: left;
  height: 32px;
  line-height: 32px;
}
.form_box >>> .el-input,
.form_box >>> .el-textarea {
  width: 60%;
}
.form_box >>> .el-select .el-input {
  width: 100%;
}
</style>